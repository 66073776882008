$packageName: checkout-mfe-web-service;
$brand: 'hol';
$package: $packageName;
$responsive-icons: null;

.#{$package} {
    // All CSS Goes here
    @import 'src/styles/fragments/custom';
    @import '../lib/components/CheckoutPage/recognize-customer-form/recognize-customer-form.hol';
    @import '../lib/components/ShoppingBag/EmptyBag/emptybag-hol';
}

@import './fragments/animate';
@import 'src/lib/components/MiniBag/MiniBag';
@import 'src/lib/components/CheckoutPage/SameDayDelivery/sameDayDeliveryModal';
@import 'src/lib/components/CheckoutPage/SameDayDelivery/sameDayDelivery';
@import 'src/lib/components/Common/RewardsPromos/rewardsPromos';
@import 'src/lib/components/Common/PayOverTime/PayOverTime';
@import 'src/lib/components/CheckoutPage/Charity/charity';
@import 'src/lib/components/Common/Loader/loader';
@import 'src/lib/components/ShoppingBag/GiftBoxModal/GiftBoxModal';
@import 'src/lib/components/ShoppingBag/OrderInStore/OrderInStore';
@import  'src/lib/components/CheckoutPage/AddressVerification/AddressVerification';
@import 'src/lib/components/CheckoutPage/OrderContactInfo/OrderContactInfo';
@import 'src/lib/components/CheckoutPage/MarketingPreferences/MarketingPreferences';
@import 'src/lib/components/CheckoutPage/PaymentManager/PaymentManager';
@import 'src/lib/components/Common/OrderTotals/OrderTotals';
@import 'src/lib/components/CheckoutPage/OrderSubmitLegalTerms/OrderSubmitLegalTerms';
@import 'src/lib/components/CheckoutPage/checkoutPage';
@import 'src/lib/components/CheckoutPage/SubmitButton/SubmitButton';
@import 'src/lib/components/CheckoutPage/PopinsModal/PopinsModal';
@import 'src/lib/components/CheckoutPage/PudosModal/PudosModal';
@import 'src/lib/components/CheckoutPage/PaymentManager/GiftCard/GiftCard';
@import 'src/lib/components/CheckoutPage/MapMarker/MapMarker';
@import 'src/lib/components/CheckoutPage/InfoWindow/InfoWindow';
@import 'src/lib/components/CheckoutPage/CreditCard/creditCard';
@import 'src/lib/components/CheckoutPage/StickyBagSummary/StickyBagSummary';
@import 'src/lib/components/OrderConfirmationPage/SmsOptIn/smsOptIn';
@import 'src/lib/components/CheckoutPage/recognize-customer-form/recognize-customer-form';
@import 'src/lib/components/CheckoutPage/ShippingHandling/ShippingHandling';
@import 'src/lib/components/Common/PopinsInfoModal/PopinsInfoModal';
@import 'src/styles/global.scss';

.mfe-bag .stickycheckout-container {
    display: none;
}

@include media-query(0,540){
    .recognize-customer-section .checkout-heading {
      padding: rem(50) rem(45) 0 rem(45);
    }
  }